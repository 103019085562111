
.RulesMain{
    /* font-family: "Azonix"; */
    position: relative;
    font-weight: 400;
    /* display: flex; */
    /* flex-flow: column nowrap; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    background: url(https://i.imgur.com/J9FjSOH.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    height: 100vh;
    overflow-x: hidden;
    width: 100vw;
    overflow-y: auto;
}

.CoverArea{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    color: white;
}

