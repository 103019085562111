.CoverArea{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    background: url(https://i.imgur.com/J9FjSOH.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    height: 100vh;
}

.Logo{
    height: 7rem;
    padding: 1rem;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Rcard{
    margin: 2.5rem 0.5rem 0.1rem 1rem;
    padding: 1rem 1rem 2rem 1rem;
    width: 73%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 10rem;

    color: white;   
    border: 1px solid white;
    border-radius: 2rem;;

    backdrop-filter: blur(4px);
    /* box-shadow: inset 0 0 0 200px hsl(0deg 0% 100% / 5%); */
    box-shadow: 2px 2px 15px #616464, 
            -2px -2px 15px #616464;
    background-color: rgba(240,230,230,.09);
}

.lin{
    color: white;
}

.lowInp button:hover a{
    color: black;
}

.header3{
    font-size: 1.9rem;
    font-family: "Azonix";
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    margin-bottom: 3rem;
    text-align: center;
}

.Ans{
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    width: 50%;
}

.Ans input{
    color: white;
    background-color: rgb(109, 110, 111, 0.6);
    border: none;
    padding: 0.8rem 1rem 0.8rem 1.2rem ;
    font-size: 1.1rem;
    border-radius: 0.5rem;
    text-align: left;
    width: 100%;
}

.Ans input::placeholder{
    text-align: left;
    font-size: 1.1rem;
    color:  white;
}
.lowerArea{
    margin-top: 1.2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.lowInp{
    margin: 1rem;
    font-family: azonix;
    cursor: pointer;
}

.lowInp button{
    color: white;
    background-color: rgb(109, 110, 111, 0.6);
    border: none;
    padding: 0.4rem 1rem 0.4rem 1rem ;
    font-size: 1.1rem;
    border-radius: 0.5rem;
    text-align: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.lowInp button:hover{
    background-color: white;
    color: black;
    transition: all 0.3s ease;
}

@media only screen and (max-width:1028px)
{
    .Rcard{
        margin-right: 1.6rem;
        width: 90%;
    }

    .Logo{
        height: 6rem;
        margin-bottom: 1rem;
    }

    .Ans{
        width: 95%;
    }
    .lowInp button{
        font-size: 0.9rem;
    }
   
}